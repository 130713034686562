.modalBackdrop {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background: rgba(0, 0, 0, 0.5);
   display: flex;
   align-items: center;
   justify-content: center;
}

.modalContent {
   background: #fff;
   padding: 20px;
   border-radius: 8px;
   width: 300px;
   text-align: center;
}

.formGroup {
   margin-bottom: 15px;
}

.modalActions button {
   margin: 0 5px;
   padding: 5px 10px;
}
