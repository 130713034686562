.smallProduct{
    display: flex;
    gap: .2rem;
    align-items: center;
}
.smallProduct img{
    height: 70px;
    width: 100px;
    object-fit: contain;
}
.smallProductInfo{
    display: flex;
    flex-direction: column;
    gap: .2rem;
    align-items: flex-start;
}
.smallProductInfo span{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: .8rem;
    font-weight: 500;
}