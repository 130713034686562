@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Marhey:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400..800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    padding: 0;
    margin: 0;
    /* font-family: 'Poppins', sans-serif; */
    scroll-behavior: smooth;
    transition: smooth;
    font-family: "sans-serif";
}