.bulkorder{
    padding:7rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: #f5f5f5;
}
.oheading{
    color: #28282B;
    font-size: 2rem;
    font-family: "sans-serif";
    font-weight:500;
}
.oline{
    height: .2rem;
    background-color:#C38154;
    width: 8%;
    border-radius:.5rem;
    border: none;
}
.ordercontainer{
    display: flex;
    align-items: center;
    justify-content: center;
}
.orderform{
    width:500px;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
}
.orderinputbox{
    padding:.8rem .4rem;
    outline: none;
    font-size:.9rem;
    border-radius: .5rem;
    border: .1rem solid #28282B;
}
.orderselectbox{
    border: .1rem solid #28282B;
    padding: .8rem .4rem;
    border-radius: .5rem;
    
}
.borderaddress{
    display: flex;
    gap: 1rem;
}
.ordersubmit{
   padding: 1rem;
   background-color: #5c3939;
   color: white;
   font-weight: bold;
   font-size: 1.1rem;
}
.ordersubmit:disabled{
    cursor: no-drop;
}
.ordersubmit:hover{
    color: #FDB515;
}