.registerSec {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8rem 4rem;
    background-color: #f5f5f5;
}

.register {
    width: 400px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem;
}

.registerTitle {
    font-family: 'Protest Strike', sans-serif;
    font-weight: 600;
    color: #28282B;
    font-size: 1.8rem;
    letter-spacing: 1.2px;
    gap: .5rem;
}

.registerContent {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
}

.registerBtn {
    border: none;
    background-color: #C38154;
    padding: .6rem .4rem;
    width: 100%;
    color: white;
    font-weight: bold;
    cursor: pointer;
}

.alreadyregister {
    display: flex;
    gap: .3rem;
}

.registerContent input {
    display: block;
    padding: .7rem .6rem;
    border: .1rem solid lightgray;
    outline: none;
    font-size: 1rem;
    border-radius: .2rem;
}

.alreadyBtn {
    border: none;
    text-decoration: underline;
}