.container {
    margin: 0rem;
    padding: 1.2rem;
    display: flex;
    flex-direction: column;
    width: 300px;
    min-height: 40rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    transition: transform 0.3s ease;

    @media screen and (max-width:500px) {
        margin: 1rem 0rem;
        padding: 0;
    }
}

.container:hover {
    transform: translateY(-5px);
}

.imageContainer {
    width: 100%;
    height: 200px;
    overflow: hidden;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.content {
    padding: 16px;
}

.title {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 8px;
    color: #333;
}

.type,
.category {
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 4px;
}

.desc {
    font-size: 0.9rem;
    color: #777;
    margin-bottom: 12px;
    line-height: 1.4;
}

.priceContainer {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.price {
    font-size: 1.1rem;
    font-weight: bold;
    color: #27ae60;
    margin-right: 8px;
}

.discount {
    font-size: 0.9rem;
    font-weight: bold;
    color: #e74c3c;
}

.rating {
    font-size: 0.9rem;
    color: #f39c12;
}

.btnContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;

    button {
        padding: 0.75rem 1rem;
        font-family: sans-serif;
        font-size: 1.2rem;
        border-radius: 0.6rem;
    }
}

.updateBtn {
    color: #f39c12;
    border: 1px solid #f39c12;
}

.updateBtn:hover {
    color: white;
    background-color: #f39c12;
    box-shadow: 0px 2px 3px gray;
}

.deleteBtn {
    color: red;
    border: 1px solid red;
}

.deleteBtn:hover {
    color: white;
    background-color: red;
    box-shadow: 0px 2px 3px gray;
}

.productForm {
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
}


.productform {
    padding: 1.2rem;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f9f9f9;

    button[type="submit"] {
        width: 100%;
        padding: 10px;
        color: #5c3939;
        border: 1px solid #5c3939;
        border-radius: 5px;
        cursor: pointer;
        font-size: 16px;
    }

    button[type="submit"]:hover {
        background-color: #5c3939;
        color: white;
        box-shadow: 0px 3px 3px gray;
    }
}

.formTitle {
    text-align: center;
    font-size: 1.2rem;
    font-family: sans-serif;
    color: #ffca41;
    font-weight: 600;
}

.formgroup {
    margin-bottom: 15px;
}

.formgroup label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.formgroup input,
.formgroup textarea {
    width: 98%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.formgroup textarea {
    resize: vertical;
    height: 100px;
}