@import url('https://fonts.googleapis.com/css2?family=Protest+Strike&display=swap');
.footer{
    background-color: white;
    padding:0rem 6rem;
    border-top: .2rem solid #28282B;
    display: flex;
    flex-direction: column;
}
.footerSec{
   display: grid;
   grid-template-columns:repeat(4 , minmax(150px,1fr));
   gap: 2rem;
}
.footerItem{
    padding:1rem;
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

/* giving style to first sec */
.footerLogo{
    font-family: "sans-serif";
    color:#5c3939;
    font-weight: 500;
    font-size: 2.5rem;
    letter-spacing:.1rem
}
.socialLinks{
    display: flex;
    flex-direction: column;
    gap:.8rem;
}
.socialTitle{
    font-size: 1rem;
    color: black;
    font-weight: 300;
}
.links{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.linkItem{
    display: flex;
    align-items: center;
    gap:.5rem;
    color: black;
    text-decoration: none;
    transition: .2s linear;
}
.linkItem:hover{
    color: #FDB515;
}

/* giving style to second div */

.quickTitle, .contactTitle,.helpTitle{
    color:#28282B;
    font-size: 1.2rem;
    font-weight: bold;
}
.quickLinks,.contacts,.helpLinks{
    margin-top:1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.quickItem{
    color: black;
    text-decoration: none;
    transition: .2s linear;
    font-size: 1rem;
}
.quickItem:hover{
    color: #FDB515;
}

/* giving style to third sec */
.contactItem{
    display: flex;
    align-items: center;
    gap: .5rem;
}
.contacts{
    display: flex;
    flex-direction: column;
}
.contactTexts{
    display: flex;
    flex-direction: column;
    gap: .3rem;

}
.footerContactTitle{
    font-weight: bold;
    font-size: 1.2rem;
    padding-left: .5rem;
}
.contacttextaddress{
    line-height:1.4;
    font-size: 1rem;
}
.contacttext{
    text-decoration: none;
    color: black;
    font-size: 1rem;
}
.helpItem{
    color: black;
    text-decoration: none;
    transition: .2s linear;
    font-size: 1rem;
}
.helpItem:hover{
    color: #FDB515;
}

.footerCopyright{
    padding: 2rem;
    text-align: center;
}
@media screen and (max-width:768px){
    .footer{
        padding: 0rem 2rem;
    }
    .footerSec{
        grid-template-columns:repeat(1,minmax(200px,1fr));
        gap: 0rem;
    }
    .footerItem{
        gap: .3rem;
    }
    .socialLinks,.quickLinks,.contacts,.helpLinks{
        gap: .8rem;
    }
    .footerCopyright{
        padding: 1rem;
    }
    .contactTitle{
       padding-left: 0;
    }
}