.products {
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: .5rem;
   padding: 2rem 6rem;
   background-color: #f3f3f3;
}

.productTitle {
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: .5rem;
}

.topheading {
   color: #28282B;
   font-size: 2rem;
   font-weight: 500;
   font-family: "sans-serif";
}

.topline {
   height: .2rem;
   background-color: #808080;
   width: 40%;
   border-radius: .5rem;
   border: none;
}

.productContainer {
   display: grid;
   grid-template-columns: repeat(4, minmax(280px, 1fr));
   gap: 2.5rem;
   padding: 2rem 0rem;
}

.productItem {
   overflow: hidden;
   position: relative;
   display: flex;
   flex-direction: column;
   border-radius: 5px;
   box-shadow: 0px 3px 3px lightgray;
   /* background-color: white; */
   /* -webkit-box-shadow: 0px 1px 5px -1px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 1px 5px -1px rgba(0,0,0,0.75);
    box-shadow: 0px 1px 5px -1px rgba(0,0,0,0.75); */
}

.productImg {
   width: 100%;
   height: 100%;
   object-fit: cover;
   scale: 1.1;
}

.productimage {
   margin: auto;
   background-color: white;
   width: 100%;
   height: 290px;
}

.productContent {
   background-color: #ffff;
   color: #28282B;
   padding-top: .8rem;
   border-top: .1rem solid #EDEADE;
}

.productText {
   display: block;
   color: #28282B;
   font-size: 1.1rem;
   font-weight: 500;
   text-align: center;
   margin-bottom: .1rem;
   transition: .2s linear;
}

.productText:hover {
   color: #808080;
   cursor: pointer;
}

.productPrice {
   display: block;
   text-align: center;
   margin-bottom: .8rem;
}

.productDescription {
   text-align: center;
   padding: 0px 10px;
}

.orgPrice {
   text-decoration: line-through;
   font-size: .8rem;
   color: silver;
}

.productSize {
   width: 100%;
   padding: .5rem;
   text-align: center;
   color: #36454F;
   border: none;
   background-color: #f5f5f5;
   border: .1rem solid #eaeaea;
   font-size: .9rem;
}

.productBtn {
   width: 100%;
   background-color: #808080;
   color: white;
   font-weight: bold;
   cursor: pointer;
   padding: .6rem 1.2rem;
   border: none;
   outline: none;
   transition: .2s linear;
   font-size: 1rem;
   letter-spacing: 1.1px;
   border: .1rem solid white;
   border-radius: 0px 0px 5px 5px;
}

.productBtn:hover {
   color: #808080;
   background-color: white;
   border: .1rem solid #808080;

}

.productEndBtn {
   border: .1rem solid #808080;
   color: #808080;
   font-weight: bold;
   padding: .8rem 1.2rem;
   background-color: transparent;
   transition: .2s linear;
   border-radius: 5px;
}

.productEndBtn:hover {
   color: white;
   background-color: #808080;

}

.discountSec {
   position: absolute;
   top: 0%;
   right: 0%;
   padding: .2rem .4rem;
   background-color: white;
   font-size: .8rem;
   color: #28282B;
}


@media screen and (max-width:768px) {
   .products {
      padding: .8rem;
   }

   /* .productImg{
      height: 180px;
   } */
   .productimage {
      width: 100%;
      height: 235px;
   }

   .productTitle .heading {
      font-size: 1.8rem;
   }

   .productContainer {
      grid-template-columns: repeat(2, minmax(150px, 1fr));
      gap: .8rem;
   }

   .productContent {
      padding-top: 1rem;
   }

   .productText {
      font-size: .9rem;
   }

   .productPrice {
      font-size: .9rem;
   }

   .orgPrice {
      font-size: .6rem;
   }

   .productSize {
      font-size: .8rem;
   }

   .productDropdown {
      font-size: .8rem;
      padding: .3rem;
   }

   .productBtn {
      padding: .6rem 1rem;
      font-size: .8rem;
   }

   .discountSec {
      padding: .2rem .3rem;
      font-size: .7rem;
   }
}