.contact{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 2.5rem;
    background-color: #f5f5f5;
    padding:8rem 6rem;
    padding-bottom: 2rem;
}
.contactTitle{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:.5rem;
}
.contactheading{
    color: #28282B;
    font-size: 2rem;
    font-family: "Marhey", sans-serif;
    font-weight:500;
}
.contactline{
    height: .2rem;
    background-color:#C38154;
    width: 30%;
    border-radius:.5rem;
    border: none;
}

.contactContainer{
    display: flex;
    align-items: flex-start;
    gap: 2rem;
    width: 100%;
}

.contactHead{
    padding: .5rem;
    width: 100%;
}
.contactHeadText{
    color:#C38154;
    letter-spacing: .2rem;
    font-size: 2rem;
}
.waveicon{
    color: #28282B;
}
.contactInfo{
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
}
.contactContent{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}
.infoText{
    font-size: 1rem;
    color:#555555;
    font-weight: 300;
    line-height: 1.5;
}
.infoHead{
    font-size:1.2rem;
    font-weight: bold;
    color:#333333;
}

.contactForm{
    flex: 1;
    padding: 2rem;
    background-color: white;
}
.formHeadText{
    color: gray;
    font-size: 1rem;
    letter-spacing: .1rem;
}
.contactForm form{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}
.contactInputBox{
    padding:.6rem .4rem;
    border: none;
    border-bottom:.1rem solid black;
    outline: none;
}
.contactForm form select{
    padding: .6rem .4rem;
}
.contactBtn{
    padding: .6rem .4rem;
    background-color:#5c3939;
    color: white;
    border: none;
    width:30%;
}


@media screen and (max-width:768px){
    .contactTitle .heading{
        font-size: 1.8rem;
    }
    .contactContainer{
         flex-direction: column;
         gap:2.5rem;
    }
    .contactInfo{
        gap:1rem;
    }
    .contactHeadText{
         font-size: 1.5rem;
    }
    .infoHead{
        font-size: 1.1rem;
    }
    .infoText{
        font-size: 1rem;
    }
    .contact{
        padding:8rem 2rem;
        gap:1.5rem;
    }

}