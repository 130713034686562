.container {
    padding: 4.5rem 0rem;
}

.adminHead {
    text-align: center;
    padding: 6rem;
    font-size: 3rem;
    font-family: sans-serif;
    background-color: #ffca41;
    color: #5c3939;
    font-weight: 600;
}

.contentSection {
    padding: 2rem 3rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    b {
        font-size: 1.2rem;
        font-family: sans-serif;
    }
}

.content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1rem;
    margin-top: 1rem;
    @media screen and (max-width: 800px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 500px) {
        grid-template-columns: 1fr;
    }
}

.listContainer {
    margin-bottom: 2rem;
}

.titleContainer {
    display: flex;
    align-items: center;

    button {
        padding: 0.5rem 0.75rem;
        border-radius: 5px;
        margin-left: 1rem;
        border: 2px solid #5c3939;
        background-color: #f0c092;
        color: #5c3939;
        font-family: sans-serif;
        font-weight: 600;
    }

    button:hover {
        background-color: #edb581;
        box-shadow: 0px 3px 3px lightgray;
    }
}

.productForm {
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
}


.productform {
    padding: 1.2rem;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f9f9f9;

    button[type="submit"] {
        width: 100%;
        padding: 10px;
        color: #5c3939;
        border: 1px solid #5c3939;
        border-radius: 5px;
        cursor: pointer;
        font-size: 16px;
    }

    button[type="submit"]:hover {
        background-color: #5c3939;
        color: white;
        box-shadow: 0px 3px 3px gray;
    }
}

.formTitle {
    text-align: center;
    font-size: 1.2rem;
    font-family: sans-serif;
    color: #ffca41;
    font-weight: 600;
}

.formgroup {
    margin-bottom: 15px;
}

.formgroup label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.formgroup input,
.formgroup textarea {
    width: 98%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.formgroup textarea {
    resize: vertical;
    height: 100px;
}