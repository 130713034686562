@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.about {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    margin-top: 4rem;
    background-color: #f5f5f5;
}

.aboutTitle {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .5rem;
}

.aboutheading {
    color: #28282B;
    font-size: 2rem;
    font-family: "sans-serif";
    font-weight: 500;
}

.aboutline {
    height: .2rem;
    background-color: #C38154;
    width: 10%;
    border-radius: .5rem;
    border: none;
}

.aboutContainer {
    display: flex;
    align-items: center;
    gap: 2rem;
    padding: 1rem 6rem;
}

.aboutContent {
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.aboutImg {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: .8rem;
}

.aboutImg .personImg {
    scale: 1.2;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.persontitle {
    text-align: center;
}

.persontitle span {
    font-size: 2rem;
    color: #6F4E37;
}

.aboutBox {
    display: flex;
    flex-direction: column;
    gap: .8rem;
}

.aboutHead {
    color: #FDB515;
    font-size: 2rem;
    color: #28282B;
    font-family: "sans-serif";
}

.aboutText {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.aboutText span {
    color: #28282B;
    font-size: 1.2rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    font-family: "sans-serif";
}

/* .aboutFooter{
    background-color:#F5CCA0;
    text-align: center;
    display: flex;
    align-items: center;
    padding:2rem 8rem;
    justify-content: center;
}
.aboutFooterText{
    color: #28282B;
    font-size: 1.2rem;
    font-weight: 300;
    line-height: 1.5;
} */
.moreaboutsec {
    margin: 6rem 0rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    padding: 1rem 8rem;
    align-items: center;
    text-align: center;
}

.moreaboutsec .aboutselfhead {
    font-size: 22px;
    font-family: "sans-serif";
    color: #030a0c;
    text-transform: uppercase;
    margin-bottom: 1rem;
    font-weight: bold;
}

.moreaboutsec p {
    font-size: 1.1rem;
    color: #28282B;
    font-family: "sans-serif";
    color: #28282B;
    font-weight: 500;
    line-height: 1.6;
}

@media screen and (max-width:768px) {
    .about {
        gap: .8rem;
    }

    .aboutContainer {
        gap: 2.5rem;
    }

    .aboutContent {
        gap: 2rem;
    }

    .aboutTitle .heading {
        font-size: 1.8rem;
    }

    .aboutContainer {
        flex-direction: column;
        padding: 1rem;
    }

    .aboutHead {
        font-size: 22px;
        text-align: left;
        padding-left: 1rem;
    }

    .moreaboutsec {
        margin-top: 2rem;
        padding: .5rem .8rem;
    }

    .moreaboutsec .aboutselfhead {
        font-size: 22px;
    }

    .moreaboutsec p {
        font-size: 1.1rem;
        line-height: 1.5;
    }


}

.about-text-strong {
    display: contents !important;
}

.aboutHead {
    opacity: 1;
    transform: none;
    font-size: 22px;
    font-family: "sans-serif";
    font-weight: bold;
}

.headerImageContainer {
    position: relative;
    width: 100%;
    height: 75vh;
    /* or your desired height */
    overflow: hidden;
}

.headerImageContainer .headerImg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Change to cover to maintain aspect ratio */
    z-index: 1;
    /* Send image behind the content */
}

.tint {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.584);
    height: 100%;
    width: 100%;
    z-index: 65;
    display: flex;
    justify-content: center;
    align-items: center;
}