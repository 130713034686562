.insta{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: .5rem;
    padding: 2rem 6rem;
    background-color: #f5f5f5;
}
.instaheading{
    color: #5c3939;
    font-size: 2.2rem;
    text-align: left;
}
.instaContainer{
    display: grid;
    grid-template-columns:repeat(5,minmax(200px,1fr));
    gap: 1rem;
    padding:1rem 0rem;
}

.instaItem{
    position: relative;
    cursor: pointer;
}
.instaItem:hover>.overlay{
    display: flex;
}
.instaImg{
    width:100%;
    height:100%;
    object-fit: cover;
}
.overlay{
    position: absolute;
    /* background-color: rgba(255, 255, 255, .4); */
    background-color: rgba(0, 0, 0, 0.2);
    top:0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
    transition: .4s linear;
}
.instaIcon{
    color:white;
    font-weight: 600;
}

@media screen and (max-width:768px){
    .insta{
        padding: 2rem;
    }  
    .instaTitle .heading{
        font-size: 1.5rem;
        font-weight: bold;
    }  

    .instaContainer{
        grid-template-columns:repeat(2,minmax(140px,1fr));
    }
}