.tos{
    padding: 8rem;
    line-height: 1.6;
}
.toshead h2{
   color: #282828;
   font-size: 1.4rem;
}

.toscontent h2 {
  font-size: 1.3rem; /* Adjust size as needed */
  line-height: 1.8; /* Optional for better readability */
}

.toscontent h3 {
  font-size: 1rem; /* Adjust size as needed */
  line-height: 1.6; /* Optional for better readability */
}


@media screen and (max-width:768px){
    .tos{
        padding:6rem 2rem;
    }
}
