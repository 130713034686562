/* for gold color #FDB515*/
@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400..800&display=swap');

.loadscreen {
    height: 1000px;
    transition: .4s linear;
}

.navbar {
    position: fixed;
    right: 0;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0rem 2rem;
    background: #f5f5f5;
    background: linear-gradient(150deg, #f5f5f5 20%, #f5f5f5 70%, #f5f5f5 100%);
    z-index: 9999;
    transition: .2s linear;
    border-bottom: .1rem solid white;
}

.navbar-hide {
    top: -10%;
}

.logo {
    height: 65px;
    width: 200px;
    padding: .3rem;
    margin-right: -90px;
}

.navhead {
    width: 100%;
    height: 100%;
    cursor: pointer;
    object-fit: contain;
}

.menubar {
    display: flex;
    align-items: center;
    gap: 2.5rem;
}

.menuItem {
    text-decoration: none;
    font-size: 1rem;
    color: black;
    font-weight: 300;
    transition: .2s linear;
    font-family: "Baloo 2", sans-serif;
}

.menuItem.bo-item {
    font-size: 1rem;
    padding: .5rem;
    /* border: .1rem solid #FDB515; */
    border-radius: .5rem;
    letter-spacing: .5px;
    color: #f5f5f5;

}

.menuItem:hover {
    color: #808080;
}

.menuItem.bo-item:hover {
    color: #808080;
}

.navIcon {
    display: flex;
    align-items: center;
    gap: 1.2rem;
}

.navBtn {
    border: none;
    background-color: white;
    border-radius: 5px;
    font-family: sans-serif;
    color: #002D62;
    padding: .5rem 1rem;
    cursor: pointer;
    font-size: 1rem;
    transition: .2s linear;
    font-weight: bold;
}

.navBtn:hover {
    background-color: #808080;
    color: white;
}

.searchIcon {
    font-weight: 300;
    font-size: 1rem;
    cursor: pointer;
    color: black;
    transition: .2s linear;
}

.searchIcon:hover {
    color: #808080;
}

.shoppingCart {
    position: relative;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.navCart {
    font-weight: 300;
    color: black;
    transition: .2s linear;
}

.navCart:hover {
    color: #808080;
}

.cartItem {
    position: absolute;
    background-color: black;
    color: white;
    font-size: .8rem;
    right: -5px;
    top: -8px;
    padding: 2px 5px;
    border-radius: .5rem;
}

.closeIcon,
.menuIcon {
    color: #808080;
    font-weight: 300;
    transition: .2s linear;
}

.searchSec {
    position: absolute;
    top: 100%;
    background-color: #f5f5f5;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    transition: .4s ease;
    border-bottom: .1rem solid lightgray;
    z-index: 2;
}

.searchTitle {
    font-size: 1.5rem;
    padding: 1rem 0rem;
}

.inputBox {
    display: flex;
    align-items: center;
    border: .1rem solid lightgray;
    background-color: white;
    width: 70%;
}

.searchInput {
    border: none;
    height: 100%;
    width: 100%;
    padding: .5rem;
    outline: none;
}

.searchResult {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: .5rem;
    width: 70%;
}

.SearchCloseIcon {
    position: absolute;
    top: 5%;
    right: 3%;
    cursor: pointer;
}

.searchBtnSec {
    padding: 1rem;
}

.useraccount {
    font-size: 2rem;
    color: white;
    transition: .2s linear;
}

.useraccount:hover {
    color: #808080;
}

.viewAllBtn {
    color: #808080;
    border: .1rem solid #808080;
    padding: .8rem 1.5rem;
    font-weight: bold;
    background-color: transparent;
    transition: .2s linear;
}

.viewAllBtn:hover {
    color: white;
    background-color: #808080;
}


/* responsive css */

@media screen and (max-width:1150px) {
    .navbar {
        justify-content: space-between;
    }

    .menubar {
        position: absolute;
        top: 100%;
        flex-direction: column;
        gap: 1.5rem;
        padding: 2rem;
        background-color: white;
        right: -120%;
        width: 100%;
        height: 100vh;
        transition: .2s linear;
    }

    .open {
        right: -10%;
    }

    .menuItem {
        color: black;
    }

    .navBtn {
        color: white;
        background-color: #808080;
    }

    .logo {
        width: 150px;
        height: 60px;
    }

    /* .navhead{
        width: 120px;
        height: 70px;
    } */
    .searchSec {
        padding: .5rem 0rem;
    }

    .searchResult {
        width: 90%;
    }

    .viewAllBtn {
        padding: .6rem 1.4rem;
    }

    .useraccount {
        color: #808080;
    }

    .menuItem.bo-item {
        color: #808080;
        border: .1rem solid #808080;
    }

    .menuItem.bo-item:hover {
        color: white;
        background-color: #808080;
    }

}

.loginIcon {
    font-weight: 300;
    font-size: 1rem;
    cursor: pointer;
    color: black;
    transition: .2s linear;
    margin-top: 3px;
}

.loginIcon:hover {
    color: #808080;
}