@import url("https://fonts.googleapis.com/css2?family=Varela+Round&display=swap");
.login_sec {
  margin-top: 77px;
  background-repeat: no-repeat;
  padding: 30px 0;
  background-image: url("../../assets/lbg1.png");
  .login_image {
    // background-color: #5c3939;
    text-align: center;
    @media screen and (max-width: 991px) {
      order: 1;
    }
    .log_img {
      width: 100%;
      @media screen and (max-width: 991px) {
        width: 400px;
      }
      @media screen and (max-width: 425px) {
        width: 250px;
      }
    }
  }
  .login_content {
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 991px) {
      order: 2;
    }
    .login {
      border: 1px solid #5c393947;
      width: 65%;
      padding: 30px;
      border-radius: 10px;
      box-sizing: border-box;
      background-color: #fff;
      @media screen and (max-width: 768px) {
        width: 90%;
      }
      .login_title {
        font-family: "Varela Round", sans-serif;
        color: #5c3939;
        text-align: center;
        font-size: 30px;
        padding-bottom: 20px;
        border-bottom: 1px solid rgba(92, 57, 57, 0.2784313725);
        margin-bottom: 30px;
      }
      .input_content_wrap {
        .login_paragraph {
          font-family: "Varela Round", sans-serif;
          font-size: 13px;
          color: #5c3939c7;
          text-align: center;
        }
        .input_main {
          label {
            font-family: "Varela Round", sans-serif;
            font-size: 13px;
            color: #5c3939;
          }
          .input_wrap {
            width: 100%;
            position: relative;
            border: 2px solid #5c3939;
            border-radius: 5px;
            height: 50px;
            margin-bottom: 30px;
            .input_img {
              position: absolute;
              height: 20px;
              top: 16px;
              left: 16px;
            }
            .divider {
              position: absolute;
              height: 40px;
              width: 2px;
              background: #5c3939;
              top: 6px;
              left: 53px;
            }
            .input {
              border: none;
              height: 50px;
              padding-left: 70px;
              border-radius: 5px;
              width: 100%;
              box-sizing: border-box;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-family: "Varela Round", sans-serif;
            //  / @media screen and (max-width: 325px) {
              //   width: 40px;
              // }
              &::placeholder {
                color: #5c39398e;
                font-family: "Varela Round", sans-serif;
              }
            }
          }
        }
      }
      .login_btn {
        width: 100%;
        height: 40px;
        border-radius: 5px;
        background: #5c3939;
        color: #f0bf93;
        font-family: "Varela Round", sans-serif;
        border: 2px solid #5c3939;
        &:hover {
          background-color: transparent;
          color: #5c3939;
        }
      }
      .new_user {
        font-size: 12px;
        text-align: end;
        color: #5c3939;
        margin-top: 20px;
        span {
          font-family: "Varela Round", sans-serif;
        }
        a {
          font-family: "Varela Round", sans-serif;
        }
      }
    }
  }
}
