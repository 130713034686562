.container {
    padding: 1.2rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    margin-bottom: 16px;
    transition: transform 0.3s ease;
    max-width: 600px;
}

.container:hover {
    transform: translateY(-5px);
}

.title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 8px;
}

.category {
    font-size: 1.1rem;
    color: #777;
    margin-bottom: 12px;
}

.desc, .requirements {
    font-size: 1rem;
    color: #555;
    margin-bottom: 12px;
    line-height: 1.6;
}

.toggleButton {
    margin-left: 8px;
    cursor: pointer;
    font-weight: bold;
    text-decoration: underline;
    color: #fdb515;
    transition: color 0.2s ease;
}

.toggleButton:hover {
    color: #5c3939;
}

.btnContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;

    button {
        padding: 0.5rem 0.75rem;
        font-family: sans-serif;
        font-size: 1rem;
        border-radius: 0.3rem;
    }
}

.updateBtn {
    color: #f39c12;
    border: 1px solid #f39c12;
}

.updateBtn:hover {
    color: white;
    background-color: #f39c12;
    box-shadow: 0px 2px 3px gray;
}

.deleteBtn {
    color: red;
    border: 1px solid red;
}

.deleteBtn:hover {
    color: white;
    background-color: red;
    box-shadow: 0px 2px 3px gray;
}

.productForm {
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
}


.productform {
    padding: 1.2rem;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f9f9f9;

    button[type="submit"] {
        width: 100%;
        padding: 10px;
        color: #5c3939;
        border: 1px solid #5c3939;
        border-radius: 5px;
        cursor: pointer;
        font-size: 16px;
    }

    button[type="submit"]:hover {
        background-color: #5c3939;
        color: white;
        box-shadow: 0px 3px 3px gray;
    }
}

.formTitle {
    text-align: center;
    font-size: 1.2rem;
    font-family: sans-serif;
    color: #ffca41;
    font-weight: 600;
}

.formgroup {
    margin-bottom: 15px;
}

.formgroup label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.formgroup input,
.formgroup textarea {
    width: 98%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.formgroup textarea {
    resize: vertical;
    height: 100px;
}
