/* $carousel-control-color:black !default; */
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lilita+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lilita+One&family=Permanent+Marker&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kalam:wght@300;400;700&family=Lilita+One&family=Permanent+Marker&family=Playwrite+DE+Grund:wght@100..400&display=swap');
/* color history
#002D62 #0530ad #034694 #0047AB #003399*/

.topheader {
  margin-top: 3.8rem;
}

.mySwiper {
  width: 100%;
  /* height: 15rem; */
  position: unset;
  padding: 2rem 2.5rem;
  position: relative;
}

.headerImageContainer {
  position: relative;
  width: 100%;
  height: 75vh;
  /* or your desired height */
  overflow: hidden;
}

.headerImageContainer .headerImg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Change to cover to maintain aspect ratio */
  z-index: 1;
  /* Send image behind the content */
}

.tint {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.584);
  height: 100%;
  width: 100%;
  z-index: 65;
  display: flex;
  justify-content: center;
  align-items: center;
}

.middle-img {
  z-index: 100;
  filter: brightness(0) invert(1);
}

@media (max-width: 640px) {
  .middle-img {
    width: 50%;
    max-width: 150px;
    height: auto;
  }
}

.innerSec {
  background-color: #012169;
  display: flex;
  align-items: center;
  height: 80vh;
}

.headerImage {
  height: 80vh;
  width: 100%;
  position: relative;
}

.secondcontent {
  position: absolute;
  top: 18%;
  left: 5%;
}

.secondcontent h1 {
  font-size: 3.2rem;
  font-family: "Lilita One", sans-serif;
  letter-spacing: 1.1px;
  color: #503C3C;
  margin-bottom: 1.5rem;
}

.secondcontent h1 .specialtext {
  color: #D04848;
}

.secondcontent .uppertag {
  font-size: 2rem;
  color: #343434;
  font-family: "Permanent Marker", cursive;
  letter-spacing: 1px;
}

.secondcontent .secondbtn {
  background: transparent;
  border: none;
  color: whitesmoke;
  font-size: 1.2rem;
  background-color: #355E3B;
  padding: .5rem 1rem;
  letter-spacing: 1.2px;
}

.thirdcontent {
  position: absolute;
  top: 66%;
  right: 3%;
}

.thirdcontent h1 {
  font-family: "Playwrite DE Grund", cursive;
  font-size: 2.3rem;
  line-height: 1.6;
  font-weight: bold;
  color: #f5f5f5;
}



.headerImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.innerSec .left {
  flex: 1;
  padding: 2rem;
}

.innerSec .right {
  padding: 0;
  flex: 2;
  height: 100%;
}

.innerSec .right img {
  width: 100%;
  height: 100%;
  clip-path: polygon(19% 0, 100% 0, 100% 100%, 0% 100%);

}

.innerSec .left .content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: flex-start;

}

.innerSec .left .content h1 {
  color: white;
  font-size: 3.6rem;
  font-family: "Oswald", sans-serif;
  line-height: 1.3;
}

.innerSec .left .content span {
  color: white;
  font-size: 1.6rem;
  font-family: "Mukta", sans-serif;
  color: #FDB515;
}

.innerSec .left .content .expBtn {
  display: inline-block;
  color: white;
  background-color: #FDB515;
  border: none;
  padding: .8rem 1rem;
  font-weight: bold;
  font-size: 1.1rem;
  transition: .2s linear;
}

.innerSec .left .content .expBtn:hover {
  letter-spacing: 1.2px;
}

/* @media screen and (max-width:1523px){
  .headerImageContainer{
      height:70vh;
  }
} */
/* @media screen and (max-width:1280px) {
  .headerImageContainer{
    height: 75vh;
  }
} */
/* Media query for screens up to 768px wide */
@media screen and (max-width: 768px) {
  .topheader {
    margin-top: 3.8rem;
  }

  /* Adjust height of the hero section image container */
  .headerImageContainer {
    height: 40vh !important;
    /* Adjust to desired height for smaller screens */
  }

  .headerImageContainer .headerImg {
    object-fit: cover;
    /* Maintains aspect ratio */
    height: 100% !important;
    width: 100% !important;
  }

  /* Additional adjustments for inner sections */
  .innerSec {
    height: 33vh;
  }

  .headerImage {
    height: 50vh;
  }

  /* Adjust middle image size on small screens */
  .middle-img {
    width: 50%;
    max-width: 150px;
    height: auto;
  }

  /* Adjust the size of the content sections */
  .secondcontent h1 {
    font-size: 1.4rem;
    margin-bottom: 0.5rem;
  }

  .secondcontent .secondbtn {
    font-size: 0.8rem;
    padding: 0.3rem 0.5rem;
  }

  .thirdcontent {
    display: none;
  }
}

/* Media query for screens up to 390px wide */
@media screen and (max-width: 390px) {
  .headerImageContainer {
    height: 30vh !important;
    /* Further reduced height for very small screens */
  }

  .section-description {
    font-size: 0.75rem;
  }
}

/*heder image css*/
.header-img-container {
  position: relative;
  width: 100%;
}

.header-img {
  max-height: 355px;
  width: 100%;
}

.explore-btn {
  position: absolute;
  top: 82%;
  left: 7%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #8d4c4c;
  color: white;
  font-size: 16px;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  border-radius: 10x;
  font-family: sans-serif;
}

@media screen and (max-width:390px) {

  .headerImageContainer {
    height: 10vh;
  }

}

/* Custom active pagination dot color */
.swiper-container .swiper-pagination-bullet-active {
  background-color: #f4f4f4 !important;
  /* Your desired active dot color */
}

/* Optional: Custom inactive dot color for better visibility */
.swiper-container .swiper-pagination-bullet {
  background-color: rgba(255, 255, 255, 0.5);
  /* Set a light color for inactive dots */
  opacity: 1;
  /* Ensure full opacity */
}

.content-section {
  padding: 2rem;
  background-color: #f9f9f9;
  display: flex;
  justify-content: center;
  text-align: center;
}


.section-description {
  width: 900px;
  justify-content: center;
  align-items: center;
  /* font-size: 1.2rem; */
  color: #333;
  line-height: 1.5;
  margin-bottom: 1.5rem;
  word-spacing: 5px;
  letter-spacing: 1.7px;
  font-family: sans-serif;
}


@media screen and (max-width:390px) {

  .section-description {
    font-size: 0.75rem;
  }

}



/*css ended*/
