.randc{
    padding:8rem;
    line-height: 1.6;
}
.cancelhead h2{
    color:#28282B;
    font-size: 2.5rem;
}

.randccontent{
    margin-top: 2rem;
}

h1 {
    color: black;
}
h2 {
    color: #555;
}
h3 {
    color: #777;
}
p, li {
    margin: 10px 0;
}
ul {
    list-style-type: disc;
    margin-left: 20px;
}
a{
    text-decoration: none;
    color: blue;
}
@media screen and (max-width:768px){
    .randc{
        padding:6rem 2rem;
    }
}
